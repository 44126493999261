<template>
  <div v-if="trade" class="pay-page">
    <div class="pay-money">
      <span class="price">
        <span v-if="paymet === 4">{{ trade.fee * 100 }}</span>
        <span v-else>￥{{ trade.fee }}</span>
      </span>
      支付剩余时间
      <van-count-down :time="trade.pay_remain * 1000" format="mm:ss" />
    </div>

    <van-radio-group v-model="paymet" :checked-color="$global.checkedColor">
      <van-cell-group
        inset
        v-for="mod in payments"
        :key="mod.value"
        :border="false"
      >
        <van-cell clickable @click="paymet = mod.value" center checked>
          <template #title>
            <i :class="'pay-mod-icon pay-icon-' + mod.name" />
            {{ mod.title }}
          </template>
          <template #right-icon>
            <van-radio :name="mod.value" icon-size="16px" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>

    <div class="end-btn-box">
      <van-button
        v-if="paymet === 4"
        round
        block
        type="warning"
        @click="coinPaying()"
      >
        <span>确认支付</span>
        <span>{{ trade.fee * 100 }}</span>
      </van-button>
      <van-button v-else round block type="warning" @click="paying()">
        <span>确认支付</span>
        <span>￥{{ trade.fee }}</span>
      </van-button>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  name: "Pay",
  data() {
    return {
      paymet: 0,
      order: null,
      type: null,
      trade: null,
      payments: [
        {
          name: "wepay",
          value: 1,
          title: "微信",
        },
        {
          name: "alipay",
          value: 2,
          title: "支付宝支付",
        },
        {
          name: "applepay",
          value: 3,
          title: "苹果支付",
        },
        {
          name: "coinpay",
          value: 4,
          title: "魔晶支付",
        },
      ],
    };
  },
  computed: {
    Coin() {
      return this.$store.state.Coin;
    },
  },
  watch: {
    paymet(val, oval) {
      if (val === 4 && this.trade.fee * 100 > this.Coin) {
        this.$toast("你的魔晶不够");
        this.paymet = oval;
      }
    },
  },
  created() {
    this.order = this.$route.params.data;
    console.log("this.order", this.order);
    console.log("this.order.is_limit", this.order.is_limit);
    if (!this.order) {
      this.$router.go(-1);
      return false;
    }
    this.trade = this.order.trade;
    this.type = this.order.type;
    //支持的支付方式
    if (this.order.payment * 1 > 0 && this.trade.pay_channel) {
      this.payments = this.payments.filter((item) => {
        return this.trade.pay_channel.includes(item.value);
      });
      this.paymet = this.payments[0].value;
    } else {
      this.payok();
    }
  },
  methods: {
    coinPaying() {
      this.$dialog
        .confirm({
          message: "确定使用魔晶支付？",
        })
        .then(() => {
          this.paying();
        })
        .catch(() => {
          // on cancel
        });
    },
    paying() {
      let payurl;
      switch (this.paymet) {
        case 1:
          payurl = "wechatpay";
          break;
        case 2:
          payurl = "alipay";
          break;
        case 2:
          payurl = "applepay";
          break;
        case 4:
          payurl = "coinpay";
          break;
      }
      this.$ajax
        .post(`/order/trade/${payurl}/`, { tid: this.trade.tid }, "loading")
        .then((res) => {
          if (this.paymet === 1) {
            this.onBridgeReady(res);
          } else if (this.paymet === 2) {
            location.href = res.order_wap;
          } else if (this.paymet === 4) {
            this.$store.commit("Coin", res.coin);
            this.payok();
          }
        });
    },
    onBridgeReady(dk) {
      wx.config({
        debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
        appId: dk.appid, // 必填，公众号的唯一标识
        timestamp: dk.timestamp, // 必填，生成签名的时间戳
        nonceStr: dk.noncestr, // 必填，生成签名的随机串
        signature: dk.sign, // 必填，签名
        jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
      });
      if (typeof WeixinJSBridge === "undefined") {
        this.$dialog.alert({
          message: "请在微信中打开支付页面",
        });
        return false;
      }
      //调用支付
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: dk.appid,
          timeStamp: dk.timestamp,
          nonceStr: dk.noncestr, //随机串
          package: "prepay_id=" + dk.prepayid,
          signType: dk.signType, //微信签名方式：MD5\RSA\SHA1
          paySign: dk.sign, //微信签名
        },
        (res) => {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            this.payok();
          } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
            this.$toast("已取消支付");
          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            this.$dialog
              .alert({
                message: "网络异常，请重试",
                closeOnPopstate: false,
              })
              .then(() => {});
          }
        }
      );
    },
    payok() {
      //支付的类别：1.商品订单；2.福袋订单；3.提货订单
      let confirmButtonText = this.type === 2 ? "抽取福袋" : "查看订单";
      this.$dialog
        .alert({
          message: "支付成功",
          confirmButtonText: confirmButtonText,
        })
        .then(() => {
          let routename,
            params = { type: this.type };
          //支付的类别：1.商品订单；2.欧气福袋订单；3.提货订单
          switch (this.type) {
            case 2:
              //抽奖
              routename = "Draw";
              params.quantity = this.order.quantity;
              params.subtype = this.order.subtype;
              params.bid = this.order.bid;
              params.oid = this.order.oid;
              params.beginner = this.order.is_limit;
              break;
            default:
              //商品或提货订单，要去订单页
              routename = "OrderDetail";
              params.bid = this.order.bid;
              params.oid = this.order.oid;
          }
          this.$router.replace({
            name: routename,
            params: params,
          });
        });
    },
  },
};
</script>
